import { ElementType, ReactNode } from "react"

import { cn } from "@/utils/cn"
import { PolymorphicComponentPropWithRef } from "@/utils/types"

type Props = {
  className?: string
}

type SpacerProps<TComponent extends ElementType> = PolymorphicComponentPropWithRef<TComponent, Props>
type SpacerComponent = <TComponent extends ElementType = "span">(props: SpacerProps<TComponent>) => ReactNode | null

export const Spacer: SpacerComponent = <TComponent extends ElementType = "span">(props: SpacerProps<TComponent>) => {
  const { as, className, ref, ...rest } = props
  const Component = as ?? "span"
  return <Component ref={ref} className={cn("dg-block", className)} {...rest} />
}
