import { ElementType, ReactNode } from "react"
import { tv, type VariantProps } from "tailwind-variants"

import { PolymorphicComponentPropWithRef } from "../../utils/types"

const headingVariants = tv({
  base: "dg-font-bold",
  variants: {
    size: {
      h1: "dg-text-2xl sm:dg-text-4xl",
      h2: "dg-text-xl sm:dg-text-3xl",
      h3: "dg-text-lg sm:dg-text-2xl",
      h4: "dg-text-base sm:dg-text-xl",
      h5: "dg-text-base sm:dg-text-lg",
      h6: "dg-text-base",
    },
    variant: {
      primary: "dg-text-sherpa-blue-950",
      secondary: "dg-text-persian-green-500",
      slate: "dg-text-slate-700",
      tertiary: "dg-font-medium dg-text-slate-700",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
})

function mapElementTypeToSize(elementType?: ElementType): VariantProps<typeof headingVariants>["size"] {
  switch (elementType) {
    case "h1":
    case "h2":
    case "h3":
    case "h4":
    case "h5":
    case "h6":
      return elementType
    default:
      return "h1"
  }
}

type HeadingProps<TComponent extends ElementType> = PolymorphicComponentPropWithRef<TComponent, Props>
type HeadingComponent = <TComponent extends ElementType = "h1">(props: HeadingProps<TComponent>) => ReactNode | null

type Props = {
  className?: string
} & VariantProps<typeof headingVariants>

export const Heading: HeadingComponent = <TComponent extends ElementType = "h1">(props: HeadingProps<TComponent>) => {
  const { as, className, size, variant, ref, ...rest } = props
  const Component = as ?? "h1"

  return (
    <Component
      className={headingVariants({ className, variant, size: size ?? mapElementTypeToSize(as) })}
      ref={ref}
      {...rest}
    />
  )
}
