import { IconLoader2 } from "@tabler/icons-react"
import { ElementType, ReactNode } from "react"
import { tv, type VariantProps } from "tailwind-variants"

import { cn } from "@/utils/cn"

import { PolymorphicComponentPropWithRef } from "../../utils/types"

export const buttonVariants = tv({
  base: "dg-inline-flex dg-items-center dg-justify-center dg-rounded-md dg-font-medium dg-ring-offset-white dg-transition-colors focus-visible:dg-outline-none focus-visible:dg-ring-2 focus-visible:dg-ring-gray-950 focus-visible:dg-ring-offset-2 disabled:dg-pointer-events-none disabled:dg-bg-gray-300 disabled:dg-text-white",
  variants: {
    variant: {
      default: "dg-bg-sherpa-blue-950 dg-text-white hover:dg-bg-sherpa-blue-800",
      outline:
        "dg-border dg-border-sherpa-blue-950 dg-bg-white dg-font-bold dg-text-sherpa-blue-950 hover:dg-bg-gray-100 hover:dg-text-sherpa-blue-950",
      secondary: "dg-bg-gray-100 dg-text-gray-700 hover:dg-bg-gray-100/80",
      ghost: "hover:dg-bg-gray-100 hover:dg-text-gray-900 disabled:dg-opacity-50",
      link: "dg-text-gray-700 dg-underline-offset-4 hover:dg-underline",
      destructive: "dg-bg-red-500 dg-text-white hover:dg-bg-red-400",
    },
    size: {
      default: "dg-h-10 dg-px-4 dg-py-2 dg-text-base",
      sm: "dg-h-9 dg-px-3 dg-text-sm",
      lg: "dg-h-10 dg-px-8 dg-text-base lg:dg-h-11 lg:dg-text-lg",
      icon: "dg-h-10 dg-w-10 dg-text-sm",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
})

type Props = VariantProps<typeof buttonVariants> & {
  isLoading?: boolean
  className?: string
}

type ButtonProps<TComponent extends ElementType> = PolymorphicComponentPropWithRef<TComponent, Props>
type ButtonComponent = <TComponent extends ElementType = "button">(props: ButtonProps<TComponent>) => ReactNode | null

export const Button: ButtonComponent = <TComponent extends ElementType = "button">(props: ButtonProps<TComponent>) => {
  const { as, children, isLoading, size, variant, className, type, ref, ...rest } = props
  const Component = as ?? "button"

  return (
    <Component
      type={type ?? "button"}
      disabled={isLoading || props.disabled}
      className={cn(buttonVariants({ variant, size }), className)}
      ref={ref}
      {...rest}
    >
      {isLoading ? (
        <div className="dg-flex dg-items-center dg-justify-center dg-gap-2">
          <IconLoader2 className="dg-h-4 dg-w-4 dg-animate-spin" />

          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </Component>
  )
}
